import { useEffect } from 'react';
import config from 'CONFIG';
import gateway from '#utils/gateway';

export default function useTracking(): void {
  useEffect(() => {
    if (!config.ANALYTICS) return;
    gateway.firstClick().catch(console.log);
  }, []);
}
